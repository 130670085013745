// import { useState, Fragment } from 'react';
// import {
//   EntryForm,
//   LabelBz,
//   BloodDrop,
//   Blood,
//   DataInput,
//   LabelCa,
//   Carbohydrates,
//   Donut,
//   LabelIu,
//   InsulinSelect,
//   InsulinOption,
//   LabelFa,
//   FactorSelect,
//   FactorOption,
//   Button,
//   InsulinUnits,
//   FullInject,
//   EmptyInject,
// } from './HomeInputStyles';
// import leereSpritze from '../../public/leereSpritze.png';
// import volleSpritze from '../../public/volleSpritze.png';
// import blood from '../../public/blood.PNG';
// import blooddrop from '../../public/blooddrop.PNG';
// import carbohydrates from '../../public/carbohydrates.png';
// import donut from '../../public/donut.png';
// import handleInsulinUnit from '../../utils/calculate';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useTranslation } from 'react-i18next';
// import { UseCorrectionFactorById } from '../../pages/api/getCorrectionFactor';
// import { UseInsulinFactorById } from '../../pages/api/getInsulinFactor';


// export default function Input() {
//   const { user, isLoading } = useAuth0();
//   const { t } = useTranslation();
//   const [value, setValue] = useState();


//   const {correctionFactors } = UseCorrectionFactorById()
//   const { insulinFactor } = UseInsulinFactorById()
 
//   if (isLoading || !user) return null;

//   // Funktion zum Abrufen des aktuellen Tageszeitfaktors
//   function handleUserDayFactor(insulinFactor) {
//     const currentHour = new Date().getHours();
//     if (currentHour >= 6 && currentHour < 11) {
//       return insulinFactor?.morningfactor || 1;
//     } else if (currentHour >= 11 && currentHour < 17) {
//       return insulinFactor?.lunchfactor || 1;
//     } else if (currentHour >= 17 && currentHour < 22) {
//       return insulinFactor?.eveningfactor || 1;
//     } else  {
//       return insulinFactor?.latefactor || 1;
//     }
//   }

//   // Funktion zum Abrufen des aktuellen Korrekturfaktors
//   function handleUserCorrectionFactor(correctionFactors) {
//     const currentHour = new Date().getHours();
//     if (currentHour >= 6 && currentHour < 11) {
//       return correctionFactors?.morningcorrectionfactor || 1;
//     } else if (currentHour >= 11 && currentHour < 17) {
//       return correctionFactors?.lunchcorrectionfactor || 1;
//     } else if (currentHour >= 17 && currentHour < 22) {
//       return correctionFactors?.eveningcorrectionfactor || 1;
//     } else  {
//       return correctionFactors?.latecorrectionfactor || 1;
//     }
//   }

//   // Form-Submit-Handler
//   async function handleSubmit(event) {
//     event.preventDefault();
//     const form = event.target;
//     const bloodsugar = form.bloodsugar.value;
//     const carbohydrates = form.carbohydrates.value;
//     const insulin = form.setinsulinSelect.value;

//     const daytimeFactor = handleUserDayFactor(insulinFactor);
//     const correctionFactor = handleUserCorrectionFactor(correctionFactors);

//     // Verwende die Berechnungsfunktion
//     const calculateUnits = handleInsulinUnit(bloodsugar, carbohydrates, daytimeFactor, correctionFactor);

//     // Setze das Ergebnis
//     setValue(calculateUnits);

//     const cardData = {
//       userMail: user.email,
//       bloodsugar: bloodsugar,
//       carbohydrates: carbohydrates,
//       insulin: insulin,
//       daytimeFactor: daytimeFactor,
//       correctionFactor: correctionFactor,
//       calculateUnit: calculateUnits,
//       date: new Date().toLocaleString('de-DE', {
//         day: '2-digit',
//         month: '2-digit',
//         year: 'numeric',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//       }),
//     };

//     const response = await fetch('https://insulinapp-api.vercel.app/api/insulindata', {
//       method: 'POST',
//       headers: {
//         'content-type': 'application/json',
//       },
//       body: JSON.stringify(cardData),
//     });

//     form.reset();
//     console.log(response)
//   }

//   const options = [
//          { value: 'Fiasp', label: 'Fiasp' },
//          { value: 'Hum Normal', label: 'Hum Normal' },
//        ];

//   return (
//     <>
//       <EntryForm
//         onSubmit={handleSubmit}
//         onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
//       >
//         <LabelBz htmlFor="bloodsugar">
//         {t('bloodsugar')} <br />
//                    mg/dl
//           <BloodDrop src={blooddrop} alt="blooddrop" />
//           <Blood src={blood} alt="blood" />
//           <DataInput
//             type="decimal"
//             name="bloodsugar"
//             placeholder={t('currentbloodglucosevalue')}
//             id="bloodsugar"
//             min={0}
//             inputMode="numeric"
//             maxLength={3}
//             required
//           />
//         </LabelBz>
//         <LabelCa htmlFor="carbohydrates">
//         {t('carbohydrates')} (Khd) <br /> g (Gramm)
//           <Carbohydrates src={carbohydrates} alt="carbohydrates" />
//           <Donut src={donut} alt="donut" />
//           <DataInput
//             type="decimal"
//             name="carbohydrates"
//             placeholder={t('howmanyKhd65g')}
//             id="carbohydrates"
//             maxLength={3}
//             min={0}
//             inputMode="numeric"
//             required
//           />
//         </LabelCa>
//         <Fragment>
//         <LabelIu htmlFor="insulin">
//              {t('whichinsulin1')} <br /> {t('whichinsulin2')}
//              <InsulinSelect
//               htmlFor="setinsulin"
//               name="setinsulinSelect"
//               id={InsulinOption.id}
//             >
//               <InsulinOption placeholder={t('chooseinsulin')}>
//                 {t('chooseinsulin')}
//               </InsulinOption>
//               {options.map((option) => (
//                 <InsulinOption
//                   name="insulinOption"
//                   key={option.label}
//                   value={option.value}
//                 >
//                   {option.label}
//                 </InsulinOption>
//               ))}
//             </InsulinSelect>
//           </LabelIu>
//           {/* <LabelIu htmlFor="insulin"> {t('chooseinsulin')}</LabelIu>
//           <InsulinSelect name="setinsulinSelect">
//             <InsulinOption>{t('chooseinsulin')}</InsulinOption>
//             <InsulinOption value="Fiasp">Fiasp</InsulinOption>
//             <InsulinOption value="Hum Normal">Hum Normal</InsulinOption>
//           </InsulinSelect> */}
//         </Fragment>
//         <InsulinUnits
//           htmlFor="insulinunits"
//           id="calculateUnits"
//           key="calculateUnits"
//           name="calculateUnits"
//         >
//           {value} / {t('injectunits')} <br /> {t('ofinsulin')}
//           <FullInject src={volleSpritze} alt="" />
//           <EmptyInject src={leereSpritze} alt="" />
//         </InsulinUnits>
//         <Button type="submit">{t('confirm')}</Button>
//         <Fragment>
//          <LabelFa htmlFor="factor">
//         {t('daytimefactor')}
//         <FactorSelect
//           htmlFor="setdayfactor"
//           name="dayfactorSelect"
//           id="dayfactorSelect"
//           value={handleUserDayFactor(insulinFactor)}  // Wert direkt setzen
//           key="daytimefactor"
//         >
//         <FactorOption
//         name="dayfactorOption"
//         value={handleUserDayFactor(insulinFactor)}  // Option mit Wert füllen
//         >
//         {t('insulinfactor')} {handleUserDayFactor(insulinFactor)}{/*  Anzeige */}
//         </FactorOption>
//         </FactorSelect>
//       <FactorSelect
//         htmlFor="setcorrectionfactor"
//         name="correctionfactorSelect"
//         id="correctionfactorSelect"
//         value={handleUserCorrectionFactor(correctionFactors)}  // Wert direkt setzen
//         key="correctionfactor"
//       >
//       <FactorOption
//         name="correctionfactorOption"
//         value={handleUserCorrectionFactor(correctionFactors)}  // Option mit Wert füllen
//       >
//         {t('correction')} {handleUserCorrectionFactor(correctionFactors)} {/* Anzeige */}
//       </FactorOption>
//       </FactorSelect>
//       </LabelFa>
//         </Fragment>
//       </EntryForm>
//     </>
//   );
// }


import { useState, Fragment } from 'react';
import {
  EntryForm,
  LabelBz,
  BloodDrop,
  Blood,
  DataInput,
  LabelCa,
  Carbohydrates,
  Donut,
  LabelIu,
  InsulinSelect,
  InsulinOption,
  LabelFa,
  FactorSelect,
  FactorOption,
  Button,
  InsulinUnits,
  FullInject,
  EmptyInject,
  SuccessMessage,
} from './HomeInputStyles';
import leereSpritze from '../../public/leereSpritze.png';
import volleSpritze from '../../public/volleSpritze.png';
import blood from '../../public/blood.PNG';
import blooddrop from '../../public/blooddrop.PNG';
import carbohydratesImg from '../../public/carbohydratesImg.png';
import donut from '../../public/donut.png';
import handleInsulinUnit from '../../utils/calculate';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { UseCorrectionFactorById } from '../../pages/api/getCorrectionFactor';
import { UseInsulinFactorById } from '../../pages/api/getInsulinFactor';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import Loading from '../PageLoader/PageLoader';



export default function Input() {
  const { user, isLoading } = useAuth0();
  const { t } = useTranslation();
  const [bloodsugar, setBloodsugar] = useState('');
  const [carbohydrates, setCarbohydrates] = useState('');
  const [insulin, setInsulin] = useState('');
  const [value, setValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState(null);
  const { correctionFactors } = UseCorrectionFactorById();
  const { insulinFactor } = UseInsulinFactorById();

  if (isLoading || !user) return null;
  if (isLoading || !correctionFactors || !insulinFactor ) return <Loading />;

  function handleUserDayFactor(insulinFactor) {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 11) return insulinFactor?.morningfactor || 1;
    if (currentHour >= 11 && currentHour < 17) return insulinFactor?.lunchfactor || 1;
    if (currentHour >= 17 && currentHour < 22) return insulinFactor?.eveningfactor || 1;
    return insulinFactor?.latefactor || 1;
  }

  function handleUserCorrectionFactor(correctionFactors) {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 11) return correctionFactors?.morningcorrectionfactor || 1;
    if (currentHour >= 11 && currentHour < 17) return correctionFactors?.lunchcorrectionfactor || 1;
    if (currentHour >= 17 && currentHour < 22) return correctionFactors?.eveningcorrectionfactor || 1;
    return correctionFactors?.latecorrectionfactor || 1;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const daytimeFactor = handleUserDayFactor(insulinFactor);
    const correctionFactor = handleUserCorrectionFactor(correctionFactors);
    const calculateUnits = handleInsulinUnit(bloodsugar, carbohydrates, daytimeFactor, correctionFactor);

    setFormData({ bloodsugar, carbohydrates, insulin, daytimeFactor, correctionFactor, calculateUnits });
    setValue(calculateUnits);
    setShowPopup(true);
  }

  async function confirmSave() {
    const cardData = {
      bloodsugar: formData.bloodsugar,
      carbohydrates: formData.carbohydrates,
      insulin: formData.insulin,
      daytimeFactor: formData.daytimeFactor,
      correctionFactor: formData.correctionFactor,
      calculateUnit: formData.calculateUnits,
      userMail: user.email,
      date: new Date().toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }),
    };

    const response = await fetch('https://insulinapp-api.vercel.app/api/insulindata', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(cardData),
    });

    if (response.ok) {
      // Reset the form values and show success message
      setBloodsugar('');
      setCarbohydrates('');
      setInsulin('');
      setTimeout(() => setValue(''), 15000)
      setShowPopup(false);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    }
  }

  return (
    <>
      {showPopup && (
        <ConfirmationPopup
          data={formData}
          onConfirm={confirmSave}
          onCancel={() => setShowPopup(false)}
        />
      )}
      {showSuccess && <SuccessMessage className="success-message">{t('dataSaved')}</SuccessMessage>}

      <EntryForm onSubmit={handleSubmit}>
        <LabelBz htmlFor="bloodsugar">
          {t('bloodsugar')} <br /> mg/dl
          <BloodDrop src={blooddrop} alt="blooddrop" />
          <Blood src={blood} alt="blood" />
          <DataInput
            type="decimal"
            name="bloodsugar"
            placeholder={t('currentbloodglucosevalue')}
            id="bloodsugar"
            min={0}
            inputMode="numeric"
            maxLength={3}
            required
            value={bloodsugar}
            onChange={(e) => setBloodsugar(e.target.value)}
          />
        </LabelBz>
        <LabelCa htmlFor="carbohydrates">
          {t('carbohydrates')} (Khd) <br /> g (Gramm)
          <Carbohydrates src={carbohydratesImg} alt="carbohydrates" />
          <Donut src={donut} alt="donut" />
          <DataInput
            type="decimal"
            name="carbohydrates"
            placeholder={t('howmanyKhd65g')}
            id="carbohydrates"
            maxLength={3}
            min={0}
            inputMode="numeric"
            required
            value={carbohydrates}
            onChange={(e) => setCarbohydrates(e.target.value)}
          />
        </LabelCa>
        <Fragment>
          <LabelIu htmlFor="insulin">
            {t('whichinsulin1')} <br /> {t('whichinsulin2')}
            <InsulinSelect
              name="setinsulinSelect"
              id="setinsulinSelect"
              value={insulin}
              onChange={(e) => setInsulin(e.target.value)}
            >
              <InsulinOption value="">{t('chooseinsulin')}</InsulinOption>
              {[
                { value: 'Fiasp', label: 'Fiasp' },
                { value: 'Hum Normal', label: 'Hum Normal' },
              ].map((option) => (
                <InsulinOption key={option.value} value={option.value}>
                  {option.label}
                </InsulinOption>
              ))}
            </InsulinSelect>
          </LabelIu>
        </Fragment>
        <Button type="submit">{t('confirm')}</Button>
        <InsulinUnits>
          {value} / {t('injectunits')} <br /> {t('ofinsulin')}
          <FullInject src={volleSpritze} alt="Full syringe" />
          <EmptyInject src={leereSpritze} alt="Empty syringe" />
        </InsulinUnits>
        <LabelFa htmlFor="factor">
          {t('daytimefactor')}
          <FactorSelect name="dayfactorSelect" id="dayfactorSelect" value={handleUserDayFactor(insulinFactor)}>
            <FactorOption value={handleUserDayFactor(insulinFactor)}>
              {t('insulinfactor')} {handleUserDayFactor(insulinFactor)}
            </FactorOption>
          </FactorSelect>
          <FactorSelect
            name="correctionfactorSelect"
            id="correctionfactorSelect"
            value={handleUserCorrectionFactor(correctionFactors)}
          >
            <FactorOption value={handleUserCorrectionFactor(correctionFactors)}>
              {t('correction')} {handleUserCorrectionFactor(correctionFactors)}
            </FactorOption>
          </FactorSelect>
        </LabelFa>
      </EntryForm>
    </>
  );
}


