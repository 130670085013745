import React, { useState } from 'react';
import { pdf, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { ExportButton, Styles } from './UserDataToPdfStyles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { IonToast } from '@ionic/react';

export default function UserDataToPdf({ cardData }) {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const userCardData = cardData.filter((data) => data.userMail === user.email);

  function arrayBufferToBase64(buffer) {
    const bytes = new Uint8Array(buffer);
    return window.btoa(String.fromCharCode(...bytes));
  }

  async function exportToPDF() {
    setIsExporting(true);

    try {
      const itemsPerPage = 8;
      const itemsPerRow = 2;

      const docChunks = [];
      for (let i = 0; i < userCardData.length; i += itemsPerPage) {
        const chunk = userCardData.slice(i, i + itemsPerPage);

        const chunkRows = [];
        for (let j = 0; j < chunk.length; j += itemsPerRow) {
          chunkRows.push(chunk.slice(j, j + itemsPerRow));
        }
        docChunks.push(chunkRows);
      }

      const doc = (
        <Document>
          {docChunks.map((chunkRows, chunkIndex) => (
            <Page key={chunkIndex} size="A4" style={Styles.page}>
              <View style={Styles.header}>
                <Text style={Styles.title}>
                  {t('saveddatafrom')} {user.given_name || user.nickname}
                </Text>
                <Text style={Styles.subtitle}>
                  {t('createdon')}: {new Date().toLocaleString()}
                </Text>
              </View>
              <View style={Styles.cardContainer}>
                {chunkRows.map((row, rowIndex) => 
                  row.map((item, itemIndex) => (
                    <View key={`${rowIndex}-${itemIndex}`} style={Styles.card}>
                      <Text style={{ color: '#008080' }}>
                        {t('date')}: {item.date}
                      </Text>
                      <Text style={{ color: '#c92a2a' }}>
                        {t('bloodsugarlevel')}: {item.bloodsugar} mg/dl
                      </Text>
                      <Text style={{ color: '#e67700' }}>
                        {t('carbohydrates')}: {item.carbohydrates} g
                      </Text>
                      <Text style={{ color: '#5c940d' }}>
                        {t('usedinsulin')}: {item.insulin}
                      </Text>
                      <Text style={{ color: '#2b8a3e' }}>
                        {t('insulinfactor')}: {item.daytimeFactor}
                      </Text>
                      <Text style={{ color: '#2b8a3e' }}>
                        {t('correctionfactor')}: {item.correctionFactor}
                      </Text>
                      <Text style={{ color: '#364fc7' }}>
                        {t('injectedinsulin')} {t('quantity')}:{' '}
                        {item.calculateUnit}
                      </Text>
                    </View>
                  ))
                )}
              </View>
              <View style={Styles.footer}>
                <Text>InsulinApp.de</Text>
              </View>
              <Image style={Styles.image} src="/pageIcon.png" />
              <Text
                style={Styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          ))}
        </Document>
      );

      const pdfBlob = await pdf(doc).toBlob();
      const pdfArrayBuffer = await pdfBlob.arrayBuffer();
      const base64Data = arrayBufferToBase64(pdfArrayBuffer);
      const fileName = `gespeicherte_daten_${user.given_name || user.nickname}_${new Date().toLocaleString()}.pdf`;

      if (Capacitor.isNativePlatform()) {
        try {
          const result = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Documents,
          });
          
          setToastMessage(t('pdfSaved'));
        } catch (error) {
          console.error('Fehler beim Speichern:', error);
          setToastMessage(`Fehler beim Speichern: ${error.message}`);
        }
      } else {
        // Browser download
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
        setToastMessage('PDF erfolgreich heruntergeladen!');
      }
    } catch (error) {
      console.error('PDF export error:', error);
      setToastMessage(`Fehler beim PDF-Export: ${error.message}`);
    } finally {
      setShowToast(true);
      setIsExporting(false);
    }
  }

  return (
    <>
      <ExportButton onClick={exportToPDF} disabled={isExporting}>
        {isExporting ? 'Exportiere...' : t('export')}
      </ExportButton>
      
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        position="bottom"
      />
    </>
  );
}

// import React, { useState } from 'react';
// import { jsPDF } from 'jspdf';
// import { Filesystem, Directory } from '@capacitor/filesystem';
// import { Capacitor } from '@capacitor/core';
// import { ExportButton } from './UserDataToPdfStyles';
// import { useTranslation } from 'react-i18next';
// import { useAuth0 } from '@auth0/auth0-react';
// import { IonToast } from '@ionic/react';
// import { Browser } from '@capacitor/browser';

// export default function UserDataToPdf({ cardData }) {
//   const { user } = useAuth0();
//   const { t } = useTranslation();
//   const [isExporting, setIsExporting] = useState(false);
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState('');

//   const userCardData = cardData.filter((cardData) => {
//     return cardData.userMail === user.email;
//   });

//   function arrayBufferToBase64(buffer) {
//     let binary = '';
//     const bytes = new Uint8Array(buffer);
//     const len = bytes.byteLength;
//     for (let i = 0; i < len; i++) {
//       binary += String.fromCharCode(bytes[i]);
//     }
//     return window.btoa(binary);
//   }

//   async function exportToPDF() {
//     try {
//       setIsExporting(true);
//       const doc = new jsPDF();
//       const itemsPerPage = 8; // Angepasst für das ursprüngliche Layout
//       let currentPage = 1;
//       const totalPages = Math.ceil(userCardData.length / itemsPerPage);

//       for (let i = 0; i < userCardData.length; i += itemsPerPage) {
//         if (i > 0) {
//           doc.addPage();
//         }

//         // Header-Bereich
//         doc.setFont('helvetica');
//         doc.setFontSize(18);
//         doc.setTextColor(102, 51, 153); // rebeccapurple für Header
//         doc.text(`${t('saveddatafrom')} ${user.name}`, doc.internal.pageSize.width / 2, 30, { align: 'center' });
        
//         doc.setFontSize(10);
//         doc.text(`${t('createdon')}: ${new Date().toLocaleString()}`, doc.internal.pageSize.width / 2, 40, { align: 'center' });

//         const pageItems = userCardData.slice(i, i + itemsPerPage);
        
//         // Karten-Container Layout
//         pageItems.forEach((item, index) => {
//           const row = Math.floor(index / 2);
//           const col = index % 2;
//           const xStart = col === 0 ? 20 : doc.internal.pageSize.width / 2 + 10;
//           const yStart = 60 + (row * 60);

//           // Karten-Rahmen
//           doc.setDrawColor(0);
//           doc.setLineWidth(0.5);
//           const cardWidth = (doc.internal.pageSize.width - 60) / 2;
//           doc.rect(xStart, yStart, cardWidth, 50);

//           // Karten-Inhalt
//           doc.setFontSize(10);
          
//           // Datum
//           doc.setTextColor(0, 128, 128); // teal
//           doc.text(`${t('date')}: ${item.date}`, xStart + 5, yStart + 10);
          
//           // Blutzucker
//           doc.setTextColor(201, 42, 42); // #c92a2a
//           doc.text(`${t('bloodsugarlevel')}: ${item.bloodsugar} mg/dl`, xStart + 5, yStart + 18);
          
//           // Kohlenhydrate
//           doc.setTextColor(230, 119, 0); // #e67700
//           doc.text(`${t('carbohydrates')}: ${item.carbohydrates} g`, xStart + 5, yStart + 26);
          
//           // Insulin
//           doc.setTextColor(92, 148, 13); // #5c940d
//           doc.text(`${t('usedinsulin')}: ${item.insulin}`, xStart + 5, yStart + 34);
          
//           // Faktoren
//           doc.setTextColor(43, 138, 62); // #2b8a3e
//           doc.text(`${t('insulinfactor')}: ${item.daytimeFactor}`, xStart + 5, yStart + 42);
//           doc.text(`${t('correctionfactor')}: ${item.correctionFactor}`, xStart + cardWidth/2, yStart + 42);
//         });

//         // Footer
//         doc.setTextColor(201, 42, 42); // #c92a2a für Footer
//         doc.setFontSize(12);
//         doc.text('InsulinApp.vercel.app', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 30, { align: 'center' });
        
//         // Seitenzahlen
//         doc.setTextColor(128, 128, 128); // grey
//         doc.setFontSize(10);
//         doc.text(
//           `${currentPage} / ${totalPages}`,
//           doc.internal.pageSize.width - 40,
//           doc.internal.pageSize.height - 30
//         );
        
//         currentPage++;
//       }

//       const pdfOutput = doc.output('arraybuffer');
//       const base64Data = arrayBufferToBase64(pdfOutput);
//       const fileName = `gespeicherte_daten_${user.name}.pdf`;
// if (Capacitor.isNativePlatform()) {
//         try {
//           const result = await Filesystem.writeFile({
//             path: fileName,
//             data: base64Data,
//             directory: Directory.Documents
//           });
          
//           if (Capacitor.getPlatform() === 'ios') {
//             const uriResult = await Filesystem.getUri({
//               directory: Directory.Documents,
//               path: fileName
//             });
            
//             // Hier ist die wichtige Änderung
//             await Browser.open({
//               url: uriResult.uri,
//               presentationStyle: 'fullscreen'
//             });
//           }
//           setToastMessage('PDF erfolgreich erstellt!');
//           setShowToast(true);
//         } catch (error) {
//           console.error('Fehler beim Speichern:', error);
//           setToastMessage(`Fehler beim Speichern: ${error.message}`);
//           setShowToast(true);
//         }
//       // if (Capacitor.isNativePlatform()) {
//       //   try {
//       //     const result = await Filesystem.writeFile({
//       //       path: fileName,
//       //       data: base64Data,
//       //       directory: Directory.Documents
//       //     });
          
//       //     if (Capacitor.getPlatform() === 'ios') {
//       //       const uriResult = await Filesystem.getUri({
//       //         directory: Directory.Documents,
//       //         path: fileName
//       //       });
//       //       window.open(uriResult.uri, '_blank');
//       //     }
//       //     setToastMessage('PDF erfolgreich erstellt!');
//       //     setShowToast(true);
//       //   } catch (error) {
//       //     console.error('Fehler beim Speichern:', error);
//       //     setToastMessage(`Fehler beim Speichern: ${error.message}`);
//       //     setShowToast(true);
//       //   }
//       } else {
//         const blob = new Blob([pdfOutput], { type: 'application/pdf' });
//         const url = URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = url;
//         link.download = fileName;
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//         URL.revokeObjectURL(url);
//         setToastMessage('PDF erfolgreich heruntergeladen!');
//         setShowToast(true);
//       }
//     } catch (error) {
//       console.error('PDF export error:', error);
//       setToastMessage(`Fehler beim PDF-Export: ${error.message}`);
//       setShowToast(true);
//     } finally {
//       setIsExporting(false);
//     }
//   }

//   return (
//     <>
//       <ExportButton onClick={exportToPDF} disabled={isExporting}>
//         {isExporting ? 'Exportiere...' : t('export')}
//       </ExportButton>
      
//       <IonToast
//         isOpen={showToast}
//         onDidDismiss={() => setShowToast(false)}
//         message={toastMessage}
//         duration={3000}
//         position="bottom"
//       />
//     </>
//   );
// }