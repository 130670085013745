import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { GoSignOut } from 'react-icons/go';
import styled from 'styled-components';

const logoutUri =
  'com.anonymous.insulinapp.auth0://dev-cbon33ssw5qzfg7u.us.auth0.com/capacitor/com.anonymous.insulinapp/callback';

const LogoutButton = () => {
  const { logout } = useAuth0();

  const doLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: logoutUri,
      },
      async openUrl(url) {
        await Browser.open({
          url,
          windowName: '_self',
        });
      },
    });
  };

  return  <Atags href="#" onClick={doLogout}>
  <GoSignOut />
</Atags>;
};

export default LogoutButton;

const Atags = styled.a`
  display: flex;
  color: darkgreen;
  justify-content: end;
  text-align: center;
  margin: -1.3rem 0.2rem 0.3rem 0.1rem;
  padding: 0.3rem 0.1rem 0rem 0.3rem;
  font-size: 1.1rem;
`;