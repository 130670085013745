// import {
//   WrapperSaved,
//   SearchData,
//   DataInput,
//   CardGrid,
//   Details,
//   Summary,
//   Timestampwraper,
//   IconWrapper,
//   Saved,
//   BloodSugar,
//   Carbohydrates,
//   Insulin,
//   Factor,
//   InsulinUnits,
//   SavedData,
//   Button,
//   CheckBox,
//   DeleteIcon,
// } from './SavedDataStyles';
// import { RiDeleteBinLine, RiLineHeight } from 'react-icons/ri';
// import { useEffect, useState } from 'react';
// import UserDataToPdf from '../UserDataToPdf/UserDataToPdf';
// import { useTranslation } from 'react-i18next';
// import { useAuth0 } from '@auth0/auth0-react';
// import Loading from '../PageLoader/PageLoader';
// import LoginButton from '../Login/Login';


// export default function SavedDataInjected() {

//   const { user } = useAuth0();
//   const { t } = useTranslation();
//   const [data, setData] = useState([]);
//   const [search, setSearch] = useState('');
//   const [selectedCards, setSelectedCards] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (user) {
//       setLoading(true); // Startet den Loader
//       fetch(`https://insulinapp-api.vercel.app/api/insulindata?userMail=${user.email}`)
//         .then((response) => response.json())
//         .then((insulinData) => {
//           setData(insulinData); // Daten speichern
//           setLoading(false); // Loader ausschalten, sobald die Daten gespeichert sind
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//           setLoading(false); // Loader ausschalten, selbst wenn ein Fehler auftritt
//         });
//     }
//   }, [user]);
  

//   // Rückgabe, wenn kein Benutzer eingeloggt ist
//   if (!user) {
//     return <div>Du bist nicht eingeloggt <br/>
//     <LoginButton />
//     </div>;
//   }

//   // Daten filtern und sortieren
//   const filteredData = data
//     .filter((item) => item.date.toLowerCase().includes(search.toLowerCase()))
//     .sort((a, b) => new Date(a.date) - new Date(b.date))
//     .reverse();
    
//   // Daten löschen
// // Funktion zum Verwalten der Checkbox-Auswahl
// const handleSelectCard = (id) => {
//   setSelectedCards((prevSelected) => {
//     if (prevSelected.includes(id)) {
//       return prevSelected.filter((selectedId) => selectedId !== id);
//     } else {
//       return [...prevSelected, id];
//     }
//   });
// };

// // Funktion zum Löschen ausgewählter Einträge
// // const removeSelectedCards = async () => {
// //   for (const id of selectedCards) {
// //     await removeCard(id); // Ruft die existierende removeCard-Funktion auf
// //   }

// //   // Nach dem Löschen: Aktualisiere den State und setze die Auswahl zurück
// //   setSelectedCards([]);
// // };

// //   async function removeCard(id) {
// //     console.log('Deleting card with ID:', id);

// //     try {
// //       const response = await fetch(`https://insulinapp-api.vercel.app/api/insulindata/${id}`, {
// //         method: 'DELETE',
// //       });

// //       if (response.ok) {
// //         console.log('Card deleted successfully');

// //         // Entferne die Karte aus dem State, um die Anzeige zu aktualisieren
// //         setData(data.filter((item) => item._id !== id));
// //       } else {
// //         const errorData = await response.json();
// //         console.error('Error deleting data:', errorData);
// //       }
// //     } catch (error) {
// //       console.error('Error deleting data:', error);
// //     }
// //   }

// // Funktion zum Löschen ausgewählter Einträge
// const removeSelectedCards = async () => {
//   // Lösche alle ausgewählten Karten auf einmal und sammle erfolgreiche Löschungen
//   const successfulDeletes = [];
//   for (const id of selectedCards) {
//     const result = await removeCard(id);
//     if (result) {
//       successfulDeletes.push(id); // Nur erfolgreiche Löschungen werden gesammelt
//     }
//   }

//   // Entferne die erfolgreich gelöschten Einträge aus dem State und aktualisiere die Auswahl
//   setData((prevData) => prevData.filter((item) => !successfulDeletes.includes(item._id)));
//   setSelectedCards([]);
// };

// async function removeCard(id) {
//   try {
//     const response = await fetch(`https://insulinapp-api.vercel.app/api/insulindata/${id}`, {
//       method: 'DELETE',
//     });

//     if (response.ok) {
//       console.log('Card deleted successfully');
//       return true; // Rückgabe true bei erfolgreichem Löschen
//     } else {
//       const errorData = await response.json();
//       console.error('Error deleting data:', errorData);
//       return false;
//     }
//   } catch (error) {
//     console.error('Error deleting data:', error);
//     return false;
//   }
// }

//   const handleChange = (e) => {
//     setSearch(e.target.value);
//   };

//   if (loading) return <Loading />;

//   return (
    
//     <WrapperSaved> 
//       <SavedData>{t('storedvalues')}</SavedData>
//       <SearchData>
//         <DataInput
//           type="text"
//           placeholder={t('search')}
//           onChange={handleChange}
//         />
//       </SearchData>
//       <UserDataToPdf cardData={data} />
//       <Button onClick={removeSelectedCards}>
//         {t('deleteSelected')}
//       </Button>
//       <CardGrid id="pdf-content">
//         {filteredData.map((item) => (
//           <><Details key={item._id}>
           
//           <Summary>
//             <CheckBox>
//              <DataInput type="checkbox" checked={selectedCards.includes(item._id)} onChange={() => handleSelectCard(item._id)} />
//              </CheckBox>
//             <Timestampwraper>{item.date}</Timestampwraper>
//             <IconWrapper>
//               <RiLineHeight />
//             </IconWrapper>
//           </Summary>
//           <Saved>
//             <DeleteIcon>
//               <RiDeleteBinLine onClick={() => removeCard(item._id)} />
//             </DeleteIcon>
//             <BloodSugar>
//               {t('bloodsugarlevel')}: <br /> {item.bloodsugar} mg/dl
//             </BloodSugar>
//             <br />
//             <Carbohydrates>
//               {t('carbohydrates')}: <br /> {item.carbohydrates} g (Gramm)
//             </Carbohydrates>
//             <br />
//             <Insulin>
//               {t('usedinsulin')}: <br /> {item.insulin}
//             </Insulin>
//             <br />
//             <Factor>
//               {t('insulinfactor')}: <br /> {item.daytimeFactor}
//             </Factor>
//             <Factor>
//               {t('correctionfactor')}: <br />
//               {item.correctionFactor}
//             </Factor>
//             <InsulinUnits>
//               {t('injectedinsulin')}
//               <br /> {t('quantity')}: {item.calculateUnit}
//             </InsulinUnits>
//           </Saved>
//         </Details>
//       </>
//         ))}
//       </CardGrid>
//     </WrapperSaved>
//   );
// }

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBinLine, RiLineHeight } from 'react-icons/ri';
import {
  WrapperSaved,
  SearchData,
  DataInput,
  CardGrid,
  Details,
  Summary,
  Timestampwraper,
  IconWrapper,
  Saved,
  BloodSugar,
  Carbohydrates,
  Insulin,
  Factor,
  InsulinUnits,
  SavedData,
  Button,
  CheckBox,
  DeleteIcon,
} from './SavedDataStyles';
import UserDataToPdf from '../UserDataToPdf/UserDataToPdf';
import Loading from '../PageLoader/PageLoader';
import LoginButton from '../Login/Login';

export default function SavedDataInjected() {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(true);
      fetch(`https://insulinapp-api.vercel.app/api/insulindata?userMail=${user.email}`)
        .then((response) => response.json())
        .then((insulinData) => {
          setData(insulinData);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [user]);

  if (!user) {
    return (
      <div>
        Du bist nicht eingeloggt <br/>
        <LoginButton />
      </div>
    );
  }

  const parseDateTimeString = (dateTimeString) => {
    const [datePart, timePart] = dateTimeString.split(' ');
    const [day, month, year] = datePart.split('.').map(num => parseInt(num, 10));
    const [hours, minutes, seconds] = timePart ? timePart.split(':').map(num => parseInt(num, 10)) : [0, 0, 0];
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const filteredData = data
    .filter((item) => item.date.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => parseDateTimeString(b.date) - parseDateTimeString(a.date));

  const handleSelectCard = (id) => {
    setSelectedCards((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const removeSelectedCards = async () => {
    const successfulDeletes = [];
    for (const id of selectedCards) {
      const result = await removeCard(id);
      if (result) {
        successfulDeletes.push(id);
      }
    }
    setData((prevData) => prevData.filter((item) => !successfulDeletes.includes(item._id)));
    setSelectedCards([]);
  };

  const removeCard = async (id) => {
    try {
      const response = await fetch(`https://insulinapp-api.vercel.app/api/insulindata/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        console.log('Card deleted successfully');
        setData((prevData) => prevData.filter((item) => item._id !== id));
        return true;
      } else {
        const errorData = await response.json();
        console.error('Error deleting data:', errorData);
        return false;
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      return false;
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  if (loading) return <Loading />;

  return (
    <WrapperSaved>
      <SavedData>{t('storedvalues')}</SavedData>
      <SearchData>
        <DataInput
          type="text"
          placeholder={t('search')}
          onChange={handleChange}
        />
      </SearchData>
      <UserDataToPdf cardData={data} />
      <Button onClick={removeSelectedCards}>
        {t('deleteSelected')}
      </Button>
      <CardGrid id="pdf-content">
        {filteredData.map((item) => (
          <Details key={item._id}>
            <Summary>
              <CheckBox>
                <DataInput
                  type="checkbox"
                  checked={selectedCards.includes(item._id)}
                  onChange={() => handleSelectCard(item._id)}
                />
              </CheckBox>
              <Timestampwraper>{item.date}</Timestampwraper>
              <IconWrapper>
                <RiLineHeight />
              </IconWrapper>
            </Summary>
            <Saved>
              <DeleteIcon>
                <RiDeleteBinLine onClick={() => removeCard(item._id)} />
              </DeleteIcon>
              <BloodSugar>
                {t('bloodsugarlevel')}: <br /> {item.bloodsugar} mg/dl
              </BloodSugar>
              <br />
              <Carbohydrates>
                {t('carbohydrates')}: <br /> {item.carbohydrates} g (Gramm)
              </Carbohydrates>
              <br />
              <Insulin>
                {t('usedinsulin')}: <br /> {item.insulin}
              </Insulin>
              <br />
              <Factor>
                {t('insulinfactor')}: <br /> {item.daytimeFactor}
              </Factor>
              <Factor>
                {t('correctionfactor')}: <br />
                {item.correctionFactor}
              </Factor>
              <InsulinUnits>
                {t('injectedinsulin')}
                <br /> {t('quantity')}: {item.calculateUnit}
              </InsulinUnits>
            </Saved>
          </Details>
        ))}
      </CardGrid>
    </WrapperSaved>
  );
}

// import React, { useEffect, useState } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useTranslation } from 'react-i18next';
// import { RiDeleteBinLine, RiLineHeight } from 'react-icons/ri';
// import {
//   WrapperSaved,
//   SearchData,
//   DataInput,
//   CardGrid,
//   Details,
//   Summary,
//   Timestampwraper,
//   IconWrapper,
//   Saved,
//   BloodSugar,
//   Carbohydrates,
//   Insulin,
//   Factor,
//   InsulinUnits,
//   SavedData,
//   Button,
//   CheckBox,
//   DeleteIcon,
// } from './SavedDataStyles';
// import UserDataToPdf from '../UserDataToPdf/UserDataToPdf';
// import Loading from '../PageLoader/PageLoader';
// import LoginButton from '../Login/Login';

// export default function SavedDataInjected() {
//   const { user } = useAuth0();
//   const { t } = useTranslation();
//   const [data, setData] = useState([]);
//   const [search, setSearch] = useState('');
//   const [selectedCards, setSelectedCards] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (user) {
//       setLoading(true);
//       fetch(`https://insulinapp-api.vercel.app/api/insulindata?userMail=${user.email}`)
//         .then((response) => response.json())
//         .then((insulinData) => {
//           setData(insulinData);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//           setLoading(false);
//         });
//     }
//   }, [user]);

//   if (!user) {
//     return (
//       <div>
//         Du bist nicht eingeloggt <br/>
//         <LoginButton />
//       </div>
//     );
//   }

//   const filteredData = data
//     .filter((item) => item.date.toLowerCase().includes(search.toLowerCase()))
//     .sort((a, b) => new Date(b.date) - new Date(a.date));

//   const handleSelectCard = (id) => {
//     setSelectedCards((prevSelected) => {
//       if (prevSelected.includes(id)) {
//         return prevSelected.filter((selectedId) => selectedId !== id);
//       } else {
//         return [...prevSelected, id];
//       }
//     });
//   };

//   const removeSelectedCards = async () => {
//     const successfulDeletes = [];
//     for (const id of selectedCards) {
//       const result = await removeCard(id);
//       if (result) {
//         successfulDeletes.push(id);
//       }
//     }
//     setData((prevData) => prevData.filter((item) => !successfulDeletes.includes(item._id)));
//     setSelectedCards([]);
//   };

//   const removeCard = async (id) => {
//     try {
//       const response = await fetch(`https://insulinapp-api.vercel.app/api/insulindata/${id}`, {
//         method: 'DELETE',
//       });

//       if (response.ok) {
//         console.log('Card deleted successfully');
//         // Update the state immediately after successful deletion
//         setData((prevData) => prevData.filter((item) => item._id !== id));
//         return true;
//       } else {
//         const errorData = await response.json();
//         console.error('Error deleting data:', errorData);
//         return false;
//       }
//     } catch (error) {
//       console.error('Error deleting data:', error);
//       return false;
//     }
//   };

//   const handleChange = (e) => {
//     setSearch(e.target.value);
//   };

//   if (loading) return <Loading />;

//   return (
//     <WrapperSaved>
//       <SavedData>{t('storedvalues')}</SavedData>
//       <SearchData>
//         <DataInput
//           type="text"
//           placeholder={t('search')}
//           onChange={handleChange}
//         />
//       </SearchData>
//       <UserDataToPdf cardData={data} />
//       <Button onClick={removeSelectedCards}>
//         {t('deleteSelected')}
//       </Button>
//       <CardGrid id="pdf-content">
//         {filteredData.map((item) => (
//           <Details key={item._id}>
//             <Summary>
//               <CheckBox>
//                 <DataInput
//                   type="checkbox"
//                   checked={selectedCards.includes(item._id)}
//                   onChange={() => handleSelectCard(item._id)}
//                 />
//               </CheckBox>
//               <Timestampwraper>{item.date}</Timestampwraper>
//               <IconWrapper>
//                 <RiLineHeight />
//               </IconWrapper>
//             </Summary>
//             <Saved>
//               <DeleteIcon>
//                 <RiDeleteBinLine onClick={() => removeCard(item._id)} />
//               </DeleteIcon>
//               <BloodSugar>
//                 {t('bloodsugarlevel')}: <br /> {item.bloodsugar} mg/dl
//               </BloodSugar>
//               <br />
//               <Carbohydrates>
//                 {t('carbohydrates')}: <br /> {item.carbohydrates} g (Gramm)
//               </Carbohydrates>
//               <br />
//               <Insulin>
//                 {t('usedinsulin')}: <br /> {item.insulin}
//               </Insulin>
//               <br />
//               <Factor>
//                 {t('insulinfactor')}: <br /> {item.daytimeFactor}
//               </Factor>
//               <Factor>
//                 {t('correctionfactor')}: <br />
//                 {item.correctionFactor}
//               </Factor>
//               <InsulinUnits>
//                 {t('injectedinsulin')}
//                 <br /> {t('quantity')}: {item.calculateUnit}
//               </InsulinUnits>
//             </Saved>
//           </Details>
//         ))}
//       </CardGrid>
//     </WrapperSaved>
//   );
// }