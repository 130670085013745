import { useTranslation } from 'react-i18next';
import {
  ModalWrapper,
  ModalContent,
  Text,
  ButtonWrapper,
  StyledButton,
} from './PopuipModalStyles';

export default function PopupModal({ onClose, onConfirm }) {
  const { t } = useTranslation();
  return (
    <ModalWrapper>
      <ModalContent>
        <Text>{t('sure')}</Text>
        <ButtonWrapper>
          <StyledButton onClick={onConfirm} primary>
            {t('yesDelete')}
          </StyledButton>
          <StyledButton onClick={onClose}>{t('cancel')}</StyledButton>
        </ButtonWrapper>
      </ModalContent>
    </ModalWrapper>
  );
}
