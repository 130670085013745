import styled from "styled-components";


export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 23;
`;

export const PopupContent = styled.div`
  background-color: beige;
  padding: 20px;
  border-radius: 5px;
  width: 70%;
  max-width: 250px;
  text-align: center;
  z-index: 24;
`;

export const Title = styled.h3`
  font-family: fantasy;
  font-size: 22px;
  color: black;
  padding: 4px;
`;

export const BloodSugar = styled.span`
  color: #c92a2a;
`;

export const Insulin = styled.span`
  color: #5c940d;
`;

export const Carbohydrates = styled.span`
  color: #e67700;
`;

export const Factor = styled.span`
  color: #2b8a3e;
`;

export const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

export const ButtonCancel = styled.button`
  margin: 10px;
  padding: 10px 20px;
  background-color: #C8323F;
  color: white;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #A91F27;
  }
`;