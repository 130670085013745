import React from 'react';
import { Button, ButtonCancel, PopupContainer, PopupContent, Title, BloodSugar, Carbohydrates, Factor, Insulin } from './ConfirmationPopupStyle';
import { useTranslation } from 'react-i18next';
import { InsulinUnits } from '../HomeInput/HomeInputStyles';


export default function ConfirmationPopup({ data, onConfirm, onCancel }) {
  const { t } = useTranslation();
  return (
    <PopupContainer>
      <PopupContent>
        <Title>{t('confirmData')}</Title>
        <BloodSugar>{t('bloodsugar')}: {data.bloodsugar} mg/dl<br/></BloodSugar>
        <Carbohydrates>{t('carbohydrates')}: {data.carbohydrates} g<br/></Carbohydrates>
        <Insulin>{t('insulin')}: {data.insulin}<br/></Insulin>
        <Factor>{t('insulinfactor')}: {data.daytimeFactor}<br/></Factor>        
        <Factor>{t('correctionfactor')}: {data.correctionFactor}<br/></Factor>
        <InsulinUnits>{t('injectunits')} <br /> {t('ofinsulin')}: {data.calculateUnits}</InsulinUnits>
        <Button onClick={onConfirm}>{t('yesSave')}</Button>
        <ButtonCancel onClick={onCancel}>{t('cancel')}</ButtonCancel>
      </PopupContent>
    </PopupContainer>
  );
}
